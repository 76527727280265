import React, { useEffect, useState } from 'react';
import { getPricingBlocks } from './services-pricing-data';
import Heading from '../../core/typography/Heading';
import 'twin.macro';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import TabPanel from '@material-ui/lab/TabPanel';
import SwipeableViews from 'react-swipeable-views';
import TabContext from '@material-ui/lab/TabContext';

import PricingBlock from '../../views/PricingBlock/PricingBlock';
import useDatalayer from '../../../../hooks/use-datalayer';

interface StyledTabsProps extends TabsProps {
  value: number;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#F9B233',
    },
  },
})((props: StyledTabsProps) => (
  // @ts-ignore
  // Change event seems to be typed weirdly. Can't get it to work
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));
const StyledTab = withStyles({
  root: {
    fontFamily: 'Boston',
    fontWeight: 700,
    fontSize: 16,
  },
})(Tab);

const tabLabels = [
  'Servizio Base',
  'Con auto di UGO',
  'Con la tua auto',
  'Con una vettura speciale',
];

const ServicesPricing = () => {
  const [value, setValue] = React.useState(0);
  const datalayer = useDatalayer();

  useEffect(() => {
    const _pricingBlocks = getPricingBlocks(
      datalayer.triggerReservationStarted
    );
    setExpanded('panel-' + _pricingBlocks[0].title);
  }, []);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<any>
  ) => {
    setExpanded(panel !== expanded ? panel : false);
    return event;
  };

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    if (typeof window !== 'undefined') {
      datalayer.v3.triggerTabSwitched(tabLabels[newValue]);
    }
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <>
      <div tw="hidden md:block">
        <TabContext value={value.toString()}>
          <StyledTabs
            tw="mb-5 border-none!"
            value={value}
            // @ts-ignore
            // Change event seems to be typed weirdly. Can't get it to work
            onChange={handleChange}
            variant="fullWidth"
          >
            <StyledTab label="Servizio Base" tw="outline-none!" />
            <StyledTab label="Con l'auto di UGO" tw="outline-none!" />
            <StyledTab label="Con la tua auto" tw="outline-none!" />
            <StyledTab label="Con una vettura speciale" tw="outline-none!" />
          </StyledTabs>
          <SwipeableViews
            axis={'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {getPricingBlocks(datalayer.triggerReservationStarted).map(
              (p, i) => (
                <TabPanel
                  key={p.title}
                  value={i.toString()}
                  style={{ padding: 0 }}
                >
                  <PricingBlock>{p.content}</PricingBlock>
                </TabPanel>
              )
            )}
          </SwipeableViews>
        </TabContext>
      </div>
      <div tw="md:hidden">
        <PricingBlock />
        {getPricingBlocks(datalayer.triggerReservationStarted).map((p, i) => (
          <div tw="bg-gray-100 p-5 mb-4" key={i}>
            <span>{p.title}</span>
            <div>{p.content}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServicesPricing;
