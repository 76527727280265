import React, { ReactNode } from 'react';
import { PrezziAccompagnamentoQuery } from '../../generated/graphql-types';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import ServicesPricing from '../components/partials/ServicesPricing/ServicesPricing';
import List from '../components/core/lists/List';
import ListItem from '../components/core/lists/ListItem';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SEO from '../components/core/meta/SEO';
import {
  getAppRedirectUri,
  HOSTNAME,
} from '../components/utils/getAppRedirectUri';
import PromoBanner from '../components/partials/PromoBanner/PromoBanner';
import 'twin.macro';
import OrangeBanner2 from '../components/partials/OrangeBanner2/OrangeBanner2';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import OtherPricing from '../components/views/OtherPricing/OtherPricing';
import SecurePayment from '../components/partials/SecurePayment/SecurePayment';
import ThreeSteps from '../components/partials/ThreeSteps/ThreeSteps';
import PartnerBanner from '../components/partials/PartnerBanner/PartnerBanner';
import PromoBanner2 from '../components/partials/PromoBanner/PromoBanner2';

const PricesAccompanimentPage = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const prezziCompagnia = useStaticQuery<PrezziAccompagnamentoQuery>(graphql`
    query PrezziAccompagnamento {
      last: file(relativePath: { eq: "IscrivitiNewsletter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      iconSafe: file(relativePath: { eq: "icon_safe.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      iconSafe2: file(relativePath: { eq: "icon_safe_2.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      servicesFaq: allContentfulFaq(
        filter: { category: { eq: "Services Pricing Page" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Prezzi accompagnamento" url="prezzi-accompagnamento" />
      <section tw="pb-20 pt-10">
        <Container>
          <Heading variant="h1" tw="mb-10">
            Prezzi del servizio
          </Heading>
          <Heading variant="h3" tw="mb-6">
            Come funziona l’accompagnamento?
          </Heading>
          <p tw="text-paragraph mb-10">
            Il servizio è <b>100% personalizzabile</b> e <b>on-demand</b>.
            Questo significa che ogni volta che prenoti un servizio lo
            costruisci sulle tue esigenze, scegliendo l’orario, la tratta, il
            mezzo di trasporto, la durata.{' '}
            <b>Non ci sono da pagare abbonamenti o costi fissi:</b> prenoti solo
            quello che ti serve e paghi quello che usi. Le tariffe sono
            pubbliche e trasparenti. Il pagamento avvene a consuntivo: così se
            pensavi che il servizio durasse di più, paghi solo la durata
            effettiva! Se invece hai bisogno di prolungare di un’ora
            l’affiancamento, l’operatore resta a tua disposizione e puoi
            regolare tutto a servizio concluso.
          </p>
          <div tw="flex justify-center">
            <div tw="max-w-full">
              <ServicesPricing />
            </div>
          </div>
          <div tw="py-16">
            <ThreeSteps />
          </div>
        </Container>
        <OrangeBanner2 />
        <Container tw="py-16 pb-20">
          <SecurePayment />
        </Container>
        {/*<PromoBanner1 />*/}
        <div tw="pb-20">
          <PromoBanner2 />
        </div>
        <Container>
          <div>
            <Heading variant="h3">Pacchetti di servizi</Heading>
            <p tw="text-paragraph mb-4">
              Prova il servizio! Se ti piace e lo vuoi usare spesso, per diversi
              bisogni, puoi preacquistare un pacchetto di servizi che userai a
              consumo. Coi pacchetti puoi beneficiare dei seguenti sconti:
            </p>
            <List tw="mb-4">
              <ListItem tw="font-bold">
                Carnet 10 servizi: 5% di sconto
              </ListItem>
              <ListItem tw="font-bold">
                Carnet 20 servizi: 10% di sconto
              </ListItem>
            </List>
            <p tw="text-paragraph">
              Quando acquisti il carnet, scegli la tipologia di servizio ovvero
              la durata e la tratta chilometrica. Se dovessero emergere esigenze
              particolari a posteriori, ti garantiamo massima flessibilità per
              modifiche e cambiare i tuoi programmi. Il tutto verrà regolato
              tramite conguaglio a consuntivo e/o saldo di eventuali costi
              aggiuntivi alla luce dell’effettivo utilizzo. Per richieste di
              pacchetti misti, lo sconto può variare in proporzione al
              preventivo formulato per te. Per informazioni sui carnet puoi
              scrivere a{' '}
              <a
                tw="text-primary-500 font-bold"
                href="mailto:info@hellougo.com"
              >
                info@hellougo.com
              </a>
              .
            </p>
          </div>
          {prezziCompagnia.servicesFaq?.edges?.length ? (
            <div tw="pt-16">
              <Heading variant="h2">Domande frequenti</Heading>
              <FaqAccordion
                faqItems={prezziCompagnia.servicesFaq.edges.map(({ node }) => ({
                  answer: renderRichText(
                    // @ts-ignore
                    node.answer,
                    renderOptions
                  ) as ReactNode,
                  question: node.question.question,
                }))}
              />
            </div>
          ) : null}
        </Container>
      </section>
      {/*<section tw="pb-16">*/}
      {/*  <Container>*/}
      {/*    <div tw="flex flex-wrap -mx-4 items-center">*/}
      {/*      <div tw="w-full lg:w-2/5 p-4">*/}
      {/*        <Heading variant="h5" tw="text-center">*/}
      {/*          Entra nella Community UGO!*/}
      {/*        </Heading>*/}
      {/*        <p tw="text-center text-paragraph mb-5">*/}
      {/*          Resta aggiornato sui temi legati alla terza età, alla gestione*/}
      {/*          della salute, all’equilibrio in famiglia. Approfondimenti,*/}
      {/*          momenti di confronto e consigli pratici per affrontare al meglio*/}
      {/*          e col sorriso ogni fase della vita.*/}
      {/*        </p>*/}
      {/*        <div>*/}
      {/*          <CollectEmail />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div tw="w-full lg:w-3/5 p-10">*/}
      {/*        <Image*/}
      {/*          alt="laptop"*/}
      {/*          tw="rounded-lg"*/}
      {/*          fluid={prezziCompagnia.last.childImageSharp.fluid}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Container>*/}
      {/*</section>*/}
      <PartnerBanner />
      <section tw="py-16">
        <Container>
          <OtherPricing commissioni compagnia />
        </Container>
      </section>
    </>
  );
};

export default PricesAccompanimentPage;
